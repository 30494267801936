import styled from "styled-components";
import { articleFont, transitionBackgroundColor, transitionColor } from "../../styles/_mixins";
import {
    textSecondary,
    borderRadius,
    headerFont,
    bpMobilePhablet,
    backgroundPrimary,
    textPrimary
} from "../../styles/_theme-variables";
import { fluidFontSizes } from '../../styles/_calculations';

const textColor = textSecondary;
const linkColor = textPrimary;

export const RelatedTechnologies = styled.div`
    margin: 0;
    list-style: none;
    display: inline-block;
`;

export const RelatedContainerItem = styled.div`
    flex: 1;
    ${fluidFontSizes.smallPrintSize}
    padding: 0.5em 0;
`;

export const RelatedContainer = styled.div`
    display: flex;
    border-top: 1px solid ${linkColor};
    border-bottom: 1px solid ${linkColor};
        
    @media screen and (min-width: ${bpMobilePhablet}) {
        flex-direction: row;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex-direction: column;
    }
`;

export const SectionHeader = styled.h2`
    font-family: ${headerFont};
    ${fluidFontSizes.header4Size}
    color: ${textColor};
    ${transitionColor};
    font-weight: 400;
`;

export const RelatedProjects = styled.ul`
`;

export const RelatedProject = styled.li`
    & a {
        text-decoration: none;
    }    
`;

export const RelatedProjectHeader = styled.h3`
    ${articleFont};

    a & {
        color: ${linkColor};
        ${transitionColor};
        text-decoration: none;
        &:hover {
            color: ${textColor};
        }
    }
`;

export const Meta = styled.div``;

export const Label = styled.span`
    ${articleFont};
    ${fluidFontSizes.smallPrintSize}
`;

export const TagsWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
`;
