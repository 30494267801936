import React from "react";
import { graphql, Link } from 'gatsby';
import * as ReactTooltip from 'react-tooltip';

import SEO from "../../components/seo"

import PageWrapper from "../../components/_molecules/page-wrapper";
import HtmlContentWrapper from "../../components/_molecules/html-content-wrapper";
import HtmlContent from "../../components/_molecules/html-content";

import { resolveMonth } from "../../framework/date-resolver";

import { TechnologyViewModel } from '../../framework/interfaces/technology-view-model.interface';
import { resolveTechnologies } from '../../framework/utils/technology-resolver.util';
import Tags from "../../components/_organisms/tags";
import {
    RelatedContainerItem,
    RelatedTechnologies,
    SectionHeader,
    RelatedContainer,
    RelatedProjects,
    RelatedProject,
    RelatedProjectHeader,
    Meta,
    TagsWrapper
} from './project-styles';

import ProjectHero from "../../components/_organisms/project-hero";

const mapRelatedProject = ({ title, slug }) => {
    return (
        <RelatedProject>
            <Link to={`portfolio/${slug}`}>
                <RelatedProjectHeader>{ title }</RelatedProjectHeader>
            </Link>
        </RelatedProject>
    );
};

// TODO: Introduce Videos
// const mapVideo = ({ src, title, type }) => {
//     if (type === 'youtube') {
//         return (<YouTube youtubeId={src} />);
//     }

//     return null;
// }

const renderRelatedTechnologies = (techWithoutLogo: TechnologyViewModel[]) => {
    return (
        <RelatedContainerItem>
            <SectionHeader>Other Technologies and Practices</SectionHeader>
            <RelatedTechnologies>
                { techWithoutLogo &&
                    <Tags tags={techWithoutLogo.map(t => t.name)} />
                }
            </RelatedTechnologies>
        </RelatedContainerItem>
    );
}


export default ({ data }) => {
    const { projectsJson, contentfulProject } = data;

    const project = contentfulProject || projectsJson;
    const isContentful = (contentfulProject && true) || false;

    const projectDate = new Date(project.date);

    const tech: TechnologyViewModel[] =
        (project.relatedTechnologies
        && project.relatedTechnologies.length > 0
        && isContentful
            ? resolveTechnologies(project.relatedTechnologies.map(r => r.title.toLowerCase()))
            : resolveTechnologies(project.relatedTechnologies))
        || [];

    const html = project.bodyContent.html || project.bodyContent.childMarkdownRemark.html;

    const techWithLogo = tech.filter(t => t.logo);
    const techWithoutLogo = tech.filter(t => !t.logo);

    const seoImage = (project.openGraphImage &&
        isContentful
            ? project.openGraphImage[0].fixed.src
            : project.openGraphImage[0].src.childImageSharp.fixed.src) || null;

    const heroImages = (isContentful && project.heroImages.map(i => ({
        src: {
            childImageSharp: {
                fluid: i.fluid,
                fixed: i.fixed,
                fluidThumb: i.fluidThumb
            }
        },
        name: i.title
    }))) || project.heroImages;

    const metaDescription = isContentful
        ? project.metaDescription.metaDescription
        : project.metaDescription;

    const logo = isContentful
        ? {
            name: project.logo.name,
            src: {
                childImageSharp: {
                    fluid: project.logo.fluid,
                    portrait: project.logo.portrait
                },
                extension: project.logo.file.contentType,
                publicURL: project.logo.file.url
            }
        }
        : project.logo;

    return (
        <>
            <SEO
                title={project.title}
                description={metaDescription}
                image={seoImage}
                slug={`/portfolio/${project.slug}`}
            />
            <ProjectHero
                images={heroImages}
                title={project.title}
                description={metaDescription}
                meta={[`${resolveMonth(projectDate)} - ${projectDate.getUTCFullYear()}`]}
                tech={techWithLogo}
                previewLink={!isContentful && project.previewUrl && `portfolio/${project.slug}/preview`}
                previewLabel="Preview..."
            />            
            <TagsWrapper>
                { project.tags &&
                    <Tags tags={project.tags} />
                }
            </TagsWrapper>
            <PageWrapper>                
                <HtmlContentWrapper>
                    { project.bodyContent && <HtmlContent html={html} /> }
                </HtmlContentWrapper>
                <Meta>
                    <RelatedContainer>
                        {
                            techWithoutLogo
                            && techWithoutLogo.length > 0
                            && renderRelatedTechnologies(techWithoutLogo)
                        }
                        { project.relatedProjects && project.relatedProjects.length > 0 &&
                            <RelatedContainerItem>
                                <SectionHeader>Related Projects</SectionHeader>
                                <RelatedProjects>
                                    { project.relatedProjects.map(mapRelatedProject) }
                                </RelatedProjects>
                            </RelatedContainerItem>
                        }
                    </RelatedContainer>
                </Meta>
            </PageWrapper>
        </>        
    );
};

export const projectQuery = graphql`
    query ProjectByPath($slug: String!) {
        contentfulProject(slug: { eq: $slug }) {
            id
            title
            metaDescription {
                id
                metaDescription
            }
            logo {
                id
                title
                fluid(maxHeight: 120) {
                    ...GatsbyContentfulFluid_noBase64
                }
                portrait: fluid(maxWidth: 120, maxHeight: 120) {
                    ...GatsbyContentfulFluid_noBase64
                }
                file {
                    contentType
                    url
                }
            }
            heroImages {
                id
                title
                fixed(width: 700, height: 350, cropFocus: TOP) {
                    ...GatsbyContentfulFixed
                }  
                fluid(quality: 90, maxWidth: 1920, maxHeight: 1080, cropFocus: TOP) {
                    ...GatsbyContentfulFluid
                }
                fluidThumb: fluid(quality: 90, maxWidth: 720, maxHeight: 480, cropFocus: TOP) {
                    ...GatsbyContentfulFluid_withWebp
                } 
            }
            openGraphImage: heroImages {
                id
                title
                fixed(width: 1200, height: 630) {
                    src
                }
            }
            bodyContent {
                id
                childMarkdownRemark {
                    html
                }
            }
            date: projectDate
            relatedTechnologies: relatedTechSkills {
                id
                title
            }
            slug
            tags
            series
        }
        projectsJson(slug: { eq: $slug }) {
            id
            title
            metaDescription
            logo {
                name
                src {
                    childImageSharp {
                        fluid(maxHeight: 120) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                        portrait: fluid(maxWidth: 120, maxHeight: 120) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }                    
                    extension
                    publicURL
                }
            }
            heroImages {
                name
                src {
                    childImageSharp {
                        fixed(width: 700, height: 350, cropFocus: NORTH) {
                            ...GatsbyImageSharpFixed
                        }  
                        fluid(quality: 90, maxWidth: 1920, maxHeight: 350) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                        fluidThumb: fluid(quality: 90, maxWidth: 720, maxHeight: 480, cropFocus: NORTH) {
                            ...GatsbyImageSharpFluid_withWebp
                        }                           
                    }   
                }
            }
            openGraphImage: heroImages {
                name
                src {
                    childImageSharp {
                        fixed(width: 1200, height: 630) {
                            src
                        }
                    }
                }
            }
            bodyContent: _bodyContent {
                id
                html
            }
            date
            relatedTechnologies
            slug
            tags
            series
            previewUrl
        }
    }
`;